<!-- 忘记密码 -->
<template>
  <div class="wangji">
    <div class="bese"></div>
    <div class="dingbu"></div>
    <div class="banxin">
      <!-- 板块 -->
      <div class="card">
        <div class="fontone">{{ "修改密码" }}</div>
        <!-- 输入手机号 -->
        <div class="bushu" v-if="bushu == 1">
          <div class="shurukaung">
            <div class="fontTwo">{{ "手机号:" }}</div>
            <el-input
              class="inline-input"
              v-model.trim="account"
              placeholder="请输入手机号"
            >
            </el-input>
          </div>
          <div class="anniu">
            <div class="btn" @click="oneyi">{{ "下一步" }}</div>
          </div>
        </div>
        <!-- 确认密保问题 -->
        <div v-else-if="bushu == 2">
          <div class="shurukaung">
            <div class="fontTwo">{{ "密保问题:" }}</div>
            <el-select v-model="value" placeholder="请选择密保问题">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="shurukaung mt27">
            <div class="fontTwo">{{ "密保答案:" }}</div>
            <el-input
              class="inline-input"
              v-model.trim="daan"
              placeholder="请输入密保答案"
            >
            </el-input>
          </div>
          <div class="anniu">
            <div class="btn" @click="hyan">{{ "获取验证码" }}</div>
          </div>
        </div>
        <!-- 验证码 -->
        <div v-else>
          <div class="shurukaung mt27">
            <div class="fontTwo">
              <span style="opacity: 0">等</span>{{ "验证码：" }}
            </div>
            <div class="yanzh">
              <el-input
                class="inline-input"
                v-model.trim="authCode"
                placeholder="请输入验证码"
              >
              </el-input>
              <div class="flex1">
                <div
                  v-if="timerNumber == 0"
                  class="fonttwo"
                  @click="SendingNumber"
                >
                  {{ "获取验证码" }}
                </div>
                <div v-else class="fonttwo">{{ timerNumber }}</div>
              </div>
            </div>
          </div>
          <!-- 第一次密码 -->
          <div class="shurukaung mt27">
            <div class="fontTwo">{{ "登录密码：" }}</div>
            <el-input
              class="inline-input"
              v-model.trim="newPossword"
              placeholder="请输入密码"
              show-password
            >
            </el-input>
          </div>
          <!-- 第二次密码 -->
          <div class="shurukaung mt27">
            <div class="fontTwo">{{ "确认密码：" }}</div>
            <el-input
              class="inline-input"
              v-model.trim="re_confirm"
              placeholder="请确认登录密码"
              show-password
            >
            </el-input>
          </div>
          <!-- 校验 -->
          <div class="jiaoyan">
            <div class="flex align-items">
              <i :class="{ acto: pnum == 1 }" class="el-icon-success ifont"></i>
              <span :class="{ acttwo: pnum == 1 }" class="pafont"
                >密码由6-20位组成</span
              >
            </div>
            <div class="flex" style="margin-top: 10px">
              <i
                :class="{ actthree: pnums == 1 }"
                class="el-icon-success ifont"
              ></i>
              <span :class="{ actfour: pnums == 1 }" class="pafont"
                >密码满足字母或特殊字符串的其中一种(特殊字符包括
                  .!@#%)</span
              >
            </div>
            <div class="flex align-items" style="margin-top: 10px">
              <i
                :class="{ acttfive: pnumss == 1 }"
                class="el-icon-success ifont"
              ></i>
              <span :class="{ actsix: pnumss == 1 }" class="pafont"
                >两次密码输入一致</span
              >
            </div>
          </div>
          <!-- 重置密码 -->
          <div class="anniu">
            <div class="btn" @click="chongzhi">{{ "重置密码" }}</div>
          </div>
        </div>
        <div class="fanhui" @click="fanhui">{{ "点击返回" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// api
import API from "@/api/modules/login.js";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      pnum: 0, //密码校验
      pnums: 0, //密码校验
      pnumss: 0, //密码校验
      bushu: 1,
      account: "", // 手机号码
      options: [
        {
          value: "1",
          label: "母亲的名字",
        },
        {
          value: "2",
          label: "爷爷的名字",
        },
        {
          value: "3",
          label: "父亲的手机号码",
        },
        {
          value: "4",
          label: "您其中一位老师的名字",
        },
        {
          value: "5",
          label: "您个人计算机的型号",
        },
        {
          value: "6",
          label: "您最喜欢的餐馆名称",
        },
        {
          value: "7",
          label: "驾驶执照最后四位数字",
        },
      ],
      value: "",
      daan: "",
      newPossword: null, //新密码
      re_confirm: null, //再次输入新密码
      timerNumber: 0, //倒计时
      timer: null, //计时器
      authCode: null, //验证码
    };
  },
  watch: {
    newPossword: function (vla, old) {
      if (vla.length < 6) {
        this.pnum = 0;
      }
      if (vla.length >= 6) {
        this.pnum = 1;
      }
      if (vla.length > 20) {
        this.pnum = 0;
      }
      const check = /[A-Za-z.!@#%]/;
        // /^(?=.{6})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[.!@#%]).*$/;

      if (check.test(vla)) {
        this.pnums = 1;
      } else {
        this.pnums = 0;
      }
      if (vla == this.re_confirm) {
        this.pnumss = 1;
      } else {
        this.pnumss = 0;
      }
    },
    re_confirm: function (vla, old) {
      if (vla == this.newPossword) {
        this.pnumss = 1;
      } else {
        this.pnumss = 0;
      }
    },
  },
  mounted() {
    // let aa = this.yonghu();
    // if (aa == true) {
    //   this.$message({
    //     message: "您已经处于登录状态!",
    //     type: "warning",
    //   });
    //   this.jump("/", this.$store.state.loginMessage);
    //   return;
    // }
  },
  methods: {
    // 测试有没有处于登录状态
    async yonghu() {
      let a = false;
      let obj = await API.userHomepage().then((res) => {
        if (res.code == 1) {
          a = true;
        }
      });
      return a;
    },
    async hyan() {
      let res = await API.userquertion({
        mobile: this.account,
        question: this.value,
        answer: this.daan,
      }).then((res) => {
        if (res.code == 1) {
          this.recycle();
          this.bushu = 3;
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    fanhui() {
      this.$router.push("/login");
    },
    // 第二步
    oneyi() {
      if (this.account == "") {
        this.$message({
          message: "请输入手机号!",
          type: "warning",
        });
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.account)) {
        this.$message({
          message: "您的手机号码输入有误,请您重新输入!",
          type: "warning",
        });
        return;
      }
      this.bushu = 2;
    },
    // 重置密码
    chongzhi() {
      if (this.yanzheng() == false) {
        return;
      }
      this.ResetSucceeded();
    },
    // 密码校验,长度6-30位,只允许数字字母下划线
    yanzheng() {
      if (this.authCode == "") {
        this.$message({
          message: "请输入验证码!",
          type: "warning",
        });
        return false;
      }
      if (this.newPossword == "") {
        this.$message({
          message: "请输入密码",
          type: "warning",
        });
        return;
      }
      if (this.newPossword.length < 6) {
        this.$message({
          message: "新密码小于六位",
          type: "warning",
        });
        return;
      }
      if (this.newPossword.length > 20) {
        this.$message({
          message: "新密码大于20位",
          type: "warning",
        });
        return;
      }
      // const check =
      //   /^(?=.{6})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[.!@#%]).*$/;
      const check = /[A-Za-z.!@#%]/;
      if (check.test(this.newPossword)) {
        // console.log("ok");
      } else {
        this.$message({
          message: "密码由大写字母,小写字母,数字和特殊字符串组成!",
          type: "warning",
        });
        return;
      }
      if (this.newPossword !== this.re_confirm) {
        this.$message({
          message: "您两次输入的密码不一致,请您重新输入!",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    // 切换到修改密码步骤
    async SendingNumber() {
      if (this.account == "") {
        this.$message({
          message: "请输入手机号!",
          type: "warning",
        });
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.account)) {
        this.$message({
          message: "您的手机号码输入有误,请您重新输入!",
          type: "warning",
        });
        return;
      }
      this.recycle();
    },
    // 计时器倒计时
    countDown() {
      if (this.timerNumber != 0) {
        this.timer = setTimeout(() => {
          this.timerNumber--;
          this.countDown();
        }, 1000);
      }
    },
    // 重新发送验证码
    async recycle() {
      this.timerNumber = 60;
      this.countDown();
      let res = await API.sms({
        mobile: this.account, //手机号
        event: "resetpwd", //'手机验证码登录'
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "验证码已发送至您的手机,请注意查看!",
            type: "success",
          });
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 重置成功
    async ResetSucceeded() {
      let res = await API.resetpwd({
        mobile: this.account,
        newpassword: this.re_confirm,
        captcha: this.authCode,
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "密码修改成功!",
            type: "success",
          });
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
      // 不管有没有重置成功都要返回首页
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
.bese {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #eff5ff !important;
  z-index: -1;
}
.wangji {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.dingbu {
  width: 100%;
  height: 127px;
  background: url("../../assets/img/login/ding.png");
  background-size: 100% 100%;
}
.banxin {
  width: 1200px;
  margin-top: 60px;
  display: flex;
  justify-content: center;
}
.card {
  width: 660px;
  background: #ffffff;
  box-shadow: 3px 8px 11px 0 rgba(49, 60, 87, 0.12);
  border-radius: 20px;
  padding: 29.03px 101px 17px 69px;
  box-sizing: border-box;
  margin-bottom: 91px;
}
.fontone {
  font-family: PingFangSC-Medium;
  //   font-size: 38px;
  font-size: 2.3vw;
  color: #131313;
  letter-spacing: 0;
  font-weight: 700;
  text-align: center;
  margin-bottom: 31.76px;
}
.fontTwo {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #191a1c;
  letter-spacing: 0;
  text-align: right;
  font-weight: 400;
  white-space: nowrap;
  margin-right: 44px;
}
.shurukaung {
  width: 100%;
  display: flex;
  align-items: center;
}
::v-deep(.el-input__inner) {
  width: 100% !important;
  height: 48px !important;
  background: #f1f1f1 !important;
  border-radius: 2px !important;
  border: 0px solid;
  font-family: PingFangSC-Regular;
  font-size: 18px !important;
  color: #191a1c !important;
  letter-spacing: 0;
  font-weight: 400;
  &::placeholder {
    font-family: PingFangSC-Regular;
    font-size: 18px !important;
    color: #484c51 !important;
    letter-spacing: 0;
    font-weight: 400 !important;
  }
}
.anniu {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
.btn {
  width: 327px;
  height: 48px;
  background: #2c74c6;
  border-radius: 2px;
  text-align: center;
  line-height: 48px;
  font-family: PingFangSC-Medium;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 500;
  cursor: pointer;
}
.fanhui {
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #296aef;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  margin-bottom: 17px;
  cursor: pointer;
  margin-top: 52px;
}
::v-deep(.el-select) {
  width: 100%;
}
::v-deep(.el-cascader) {
  .el-input__icon:before {
    content: "\e790";
    font-size: 18px !important;
    color: #6c7075 !important;
  }
}

::v-deep(.el-select) {
  .el-select__caret:before {
    content: "\e790";
    font-size: 18px !important;
    color: #6c7075 !important;
  }
}
.mt27 {
  margin-top: 27px;
}
.yanzh {
  display: flex;
  align-items: center;
  flex: 1;
  background: #f1f1f1 !important;
  border-radius: 2px !important;
  padding: 0 16px 0 0;
  box-sizing: border-box;
}
.fonttwo {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #2c74c6;
  letter-spacing: 0;
  text-align: right;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
}
.jiaoyan {
  margin-top: 27px;
  margin-left: calc(90px + 44px);
  .ifont {
    font-size: 20px;
    color: #888888;
  }
  .pafont {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #888888;
    margin-left: 5px;
  }
  .acto {
    color: #339933 !important;
  }

  .acttwo {
    color: #000 !important;
  }

  .actthree {
    color: #339933 !important;
  }

  .actfour {
    color: #000 !important;
  }
  .acttfive {
    color: #339933 !important;
  }
  .actsix {
    color: #000 !important;
  }
}
</style>